import PageLayout from '../../components/PageLayout';
import { Box, Typography, useTheme } from '@mui/material';
import Cathegory from '../../components/Main/components/Cathegory';
import likesSvg from '../../../../assets/images/dashboard/cathegory/likes.svg';
import commentsSvg from '../../../../assets/images/dashboard/cathegory/comments.svg';
import secretFansSvg from '../../../../assets/images/dashboard/cathegory/secret_fans.svg';
import followersSvg from '../../../../assets/images/dashboard/cathegory/followers.svg';
import newFollowersSvg from '../../../../assets/images/dashboard/cathegory/new.svg';
import inactiveSvg from '../../../../assets/images/dashboard/cathegory/inactive.svg';
import friendsSvg from '../../../../assets/images/dashboard/cathegory/friends.svg';
import fansSvg from '../../../../assets/images/dashboard/cathegory/fans.svg';
import BuilbImg from '../../../../assets/images/dashboard/bulb.webp';

import React from 'react';
import Divider from '../../components/Divider';
import NavigationBlock from '../../components/NavigationBlock';
import { AppRoutes } from '../../../../utils/constants';
import Posts from './components/Posts';
import UserEngagement from './components/UserEngagement';
import IntroductionToAnalytics from './components/IntroductionToAnalytics';
import useScrollTop from '../../../../hooks/useScrollTop';
import { useAppState } from '../../../../providers/AppProvider.hooks';

export default function Analytics() {
  const { showUpdateModal } = useAppState();

  useScrollTop();

  const theme = useTheme();

  const sectionTitleSx = {
    color: theme.palette.text.primary,
    fontFamily: 'Avenir Next',
    fontSize: '24px',
    fontWeight: 600,
    margin: '40px 0 15px',
    lineHeight: '32.78px',
  };

  return (
    <PageLayout title="Analytics" showUpdateModal={showUpdateModal}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 20px 0',
        }}
      >
        <IntroductionToAnalytics />
        <Divider margin="25px 0 0" />
        <Typography sx={sectionTitleSx}>User Engagement</Typography>
        <UserEngagement />
        <Typography sx={sectionTitleSx}>Likes & Comments</Typography>

        <Box display="flex" gap="15px">
          <Cathegory
            cathegoryKey="likes"
            title="Likes"
            image={likesSvg}
            dataKey="other"
          ></Cathegory>
          <Cathegory
            cathegoryKey="comments"
            title="Comments"
            image={commentsSvg}
            dataKey="other"
          ></Cathegory>
        </Box>

        <Typography sx={sectionTitleSx}>Most Liked Posts</Typography>

        <Box>
          <Posts postsKey="mostLikedPosts" />
        </Box>

        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontFamily: 'Avenir Next',
            fontSize: '24px',
            fontWeight: 600,
            margin: '40px 0 15px',
            lineHeight: '32.78px',
          }}
        >
          Audience
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box display="flex" gap="15px">
            <Cathegory
              cathegoryKey="lostFollowers"
              title="Lost followers"
              image={followersSvg}
            ></Cathegory>
            <Cathegory
              cathegoryKey="newFollowers"
              title="New followers"
              image={newFollowersSvg}
            ></Cathegory>
          </Box>
          <Box display="flex" gap="15px">
            <Cathegory
              cathegoryKey="secretFans"
              title="Secret fans"
              image={secretFansSvg}
            ></Cathegory>
            <Cathegory
              cathegoryKey="inactiveFollowers"
              title="Inactive followers"
              image={inactiveSvg}
            ></Cathegory>
          </Box>
          <Box display="flex" gap="15px">
            <Cathegory
              cathegoryKey="friends"
              title="Your friends"
              image={friendsSvg}
            ></Cathegory>
            <Cathegory
              cathegoryKey="fans"
              title="Your fans"
              image={fansSvg}
            ></Cathegory>
          </Box>
        </Box>
        <Divider margin="40px 0" />

        <NavigationBlock
          title="We have prepared for you a number of tips and insights to improve your profile"
          path={AppRoutes.INSIGHTS}
          image={BuilbImg}
          buttonText="View insights"
        />
      </Box>
    </PageLayout>
  );
}
