import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useAppState } from '../../../../../providers/AppProvider.hooks';
import { numberWithCommas } from '../../../../../utils/numbers';
import minuteArrow from '../../../../../assets/svg/clock/gray/group_2.svg';
import clockBase from '../../../../../assets/svg/clock/gray/group_1.svg';
import hourArrow from '../../../../../assets/svg/clock/gray/group_3.svg';

function Cathegory({
  cathegoryKey,
  title,
  image,
  dataKey,
}: {
  cathegoryKey: string;
  title: string;
  image: string;
  dataKey?: string;
}) {
  const {
    instagramUserData,
    //setCathegoryData
  } = useAppState();
  const theme = useTheme();

  const data = useMemo(() => {
    if (instagramUserData) {
      return dataKey === 'other'
        ? instagramUserData._calculatedData.other[
            cathegoryKey as keyof typeof instagramUserData._calculatedData.other
          ]
        : instagramUserData._calculatedData.audience[
            cathegoryKey as keyof typeof instagramUserData._calculatedData.audience
          ];
    }
  }, [instagramUserData, cathegoryKey, dataKey]);

  return (
    <Box
      sx={{
        background: 'rgb(245, 245, 245)',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        width: '100%',
        // cursor: 'pointer',
      }}
      onClick={() => {
        if (data?.items) {
          // setCathegoryData(data.items);
        }
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          background: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={image} alt={title}></img>
      </Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontFamily: 'Avenir Next',
          fontSize: '14px',
          color: theme.palette.text.secondary,
          lineHeight: '20px',
          marginTop: '8px',
          marginBottom: '2px',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>

      {data?.totalValue === null ? (
        <Box
          sx={{
            minWidth: '24px',
            minHeight: '24.5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& img': {
              position: 'absolute',
            },
          }}
        >
          <Box
            className="hourArrow"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <img
              style={{
                transform: 'translateY(-2.5px)',
              }}
              src={hourArrow}
              alt="clock"
            ></img>
          </Box>
          <img src={clockBase} alt="clock"></img>
          <Box
            className="minuteArrow"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                transform: 'translateY(-3.5px)',
              }}
              src={minuteArrow}
              alt="clock"
            ></img>
          </Box>
        </Box>
      ) : (
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '20px',
            color: theme.palette.text.primary,
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          {numberWithCommas(data?.totalValue)}
        </Typography>
      )}
    </Box>
  );
}

export default Cathegory;
