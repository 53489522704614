import { useEffect } from 'react';

export default function usePopState() {
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    });
  }, []);
}
