import React, { useMemo } from 'react';
import HeroMobile from './components/Hero/HeroMobile';
import Main from './components/Main/Main';
import Footer from './components/Footer';
import InstagramAccount from './components/InstagramAccount/InstagramAccount';
import { useAppState } from '../../providers/AppProvider.hooks';
import CathegoryModal from './components/Main/components/CathegoryModal';

import Layout from './components/Layout';
import InsightsProcessingModal from './components/Main/modals/InsightsProcessingModal';
import useScrollTop from '../../hooks/useScrollTop';
import usePreloadImages from '../../hooks/usePreloadImages';

import support_svg from '../../assets/svg/menu_icons/support.svg';
import instagram_svg from '../../assets/svg/menu_icons/instagram.svg';
import terms_svg from '../../assets/svg/menu_icons/terms.svg';
import privacy_svg from '../../assets/svg/menu_icons/privacy.svg';
import log_out_svg from '../../assets/svg/menu_icons/log_out.svg';
import log_out_logo from '../../assets/svg/log_out.svg';
import file_heart_svg from '../../assets/svg/menu_icons/file-heart.svg';
import heart_hand_svg from '../../assets/svg/menu_icons/heart-hand.svg';
import bell_svg from '../../assets/svg/menu_icons/bell.svg';

import AnalyticsImage from '../../assets/images/dashboard/navigation-card/analytics-bg-card.webp';
import InsightsImage from '../../assets/images/dashboard/navigation-card/insights-bg-card.webp';
import InsightsImageOp from '../../assets/images/dashboard/navigation-card/insights--op-bg-card.webp';
import NewsImage from '../../assets/images/dashboard/navigation-card/news-bg-card.webp';
import FooterImage from '../../assets/images/dashboard/footer_image.webp';
import BellImage from '../../assets/images/dashboard/bell.webp';
import BulbImage from '../../assets/images/dashboard/bulb.webp';
import AnalyticsHeadImage from '../../assets/images/dashboard/analytics-head-bg.webp';

import LikesIcon from '../../assets/images/dashboard/cathegory/likes.svg';
import CommentsIcon from '../../assets/images/dashboard/cathegory/comments.svg';
import FollowersIcon from '../../assets/images/dashboard/cathegory/followers.svg';
import NewIcon from '../../assets/images/dashboard/cathegory/new.svg';
import FriendsIcon from '../../assets/images/dashboard/cathegory/friends.svg';
import FansIcon from '../../assets/images/dashboard/cathegory/fans.svg';
import InactiveIcon from '../../assets/images/dashboard/cathegory/inactive.svg';
import SecretFansIcon from '../../assets/images/dashboard/cathegory/secret_fans.svg';

import ArrowLeft from '../../assets/svg/arrow-narrow-left.svg';
import ArrowRight from '../../assets/svg/arrow-right-2.svg';

import { resolveRelativeImageUrl } from '../../utils/posts';

function Dashboard() {
  useScrollTop();

  const {
    instagramUserData,
    setIsShowProcessingInsightsModal,
    isShowProcessInsightsModal,
    showUpdateModal,
  } = useAppState();

  const preloadImages = useMemo(() => {
    return [
      file_heart_svg,
      heart_hand_svg,
      bell_svg,
      support_svg,
      instagram_svg,
      terms_svg,
      privacy_svg,
      log_out_svg,
      log_out_logo,
      AnalyticsHeadImage,
      BellImage,
      BulbImage,
      AnalyticsImage,
      InsightsImage,
      InsightsImageOp,
      NewsImage,
      FooterImage,
      LikesIcon,
      CommentsIcon,
      FollowersIcon,
      NewIcon,
      FriendsIcon,
      FansIcon,
      InactiveIcon,
      SecretFansIcon,
      ArrowLeft,
      ArrowRight,
      ...(instagramUserData?._news?.map((newsItem) =>
        resolveRelativeImageUrl(newsItem.imagePath)
      ) ?? []),
    ];
  }, [instagramUserData?._news]);

  usePreloadImages({
    images: preloadImages,
  });

  return (
    <Layout>
      {instagramUserData ? (
        <>
          <HeroMobile />
          <Main />
          <Footer />

          <CathegoryModal />

          <InsightsProcessingModal
            isModalOpen={isShowProcessInsightsModal}
            setModalOpen={setIsShowProcessingInsightsModal}
          />
        </>
      ) : (
        <InstagramAccount />
      )}
    </Layout>
  );
}

export default Dashboard;
