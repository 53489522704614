import PageLayout from '../../components/PageLayout';
import { Box, Typography } from '@mui/material';
import { NewsItem } from '../../../../services/requests';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { useNavigate } from 'react-router-dom';
import Divider from '../../components/Divider';
import { resolveRelativeImageUrl } from '../../../../utils/posts';
import useScrollTop from '../../../../hooks/useScrollTop';

const NewsCard = (
  newsItem: NewsItem & { onClick: () => void; useDivider: boolean }
) => {
  return (
    <>
      <Box
        onClick={newsItem.onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          cursor: 'pointer',
          '& > .card-image': {
            width: '80px',
            minWidth: '80px',
            height: '80px',
            borderRadius: '12px',
            objectFit: 'cover',
            overflow: 'hidden',
          },
        }}
      >
        <img
          className="card-image"
          src={newsItem.imagePath}
          alt={newsItem.title}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '17px',
            fontWeight: '600',
            lineHeight: '23.22px',
          }}
        >
          {newsItem.title}
        </Typography>
      </Box>
      {newsItem.useDivider && <Divider margin="20px 0" />}
    </>
  );
};

export default function News() {
  useScrollTop();

  const { instagramUserData } = useAppState();
  const navigate = useNavigate();

  const news = instagramUserData?._news ?? [];

  return (
    <PageLayout title="News">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 20px 0',
        }}
      >
        {news.map((newsItem, index) => (
          <NewsCard
            key={index}
            {...newsItem}
            imagePath={resolveRelativeImageUrl(newsItem.imagePath)}
            useDivider={index !== news.length - 1}
            onClick={() => navigate(newsItem.slug)}
          />
        ))}
      </Box>
    </PageLayout>
  );
}
