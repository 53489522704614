import { InsightsItem } from '../../../../services/requests';

export function transformInsightsItem(
  item: InsightsItem,
  index: number
): InsightsItem & { step: string } {
  const recommendationSteps = [
    '1st recommendation',
    '2nd recommendation',
    '3rd recommendation',
    '4th recommendation',
    '5th recommendation',
    '6th recommendation',
    '7th recommendation',
    '8th recommendation',
    '9th recommendation',
    '10th recommendation',
  ];

  return {
    ...item,
    step:
      recommendationSteps[index] ??
      recommendationSteps[recommendationSteps.length - 1],
  };
}
