import { Nullable } from '../models/common';

export const resolveUserCredentials = (
  credentials: Nullable<string>,
  retry = 1
): Nullable<{ email: string; password: string }> => {
  if (credentials !== null && credentials !== '') {
    try {
      return JSON.parse(atob(credentials));
    } catch (error) {
      return retry > 0
        ? resolveUserCredentials(decodeURIComponent(credentials), retry - 1)
        : null;
    }
  } else {
    return null;
  }
};
