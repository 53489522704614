import { Box, Typography, useTheme } from '@mui/material';
import BaseButton from './Button';
import { useNavigate } from 'react-router-dom';

export default function NavigationBlock({
  image,
  title,
  buttonText,
  path,
}: {
  image: string;
  title: string;
  buttonText: string;
  path: string;
}) {
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& > img': {
          maxWidth: '331px',
          width: '100%',
          margin: '0 auto',
        },
      }}
    >
      <img className="main-img" src={image} alt={title} />

      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '32.78px',
          textAlign: 'center',
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>

      <BaseButton withIcon onClick={() => navigate(path)}>
        {buttonText}
      </BaseButton>
    </Box>
  );
}
