import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseModal from './BaseModal';
import log_out_logo from '../../assets/svg/log_out.svg';
import { useAppState } from '../../providers/AppProvider.hooks';

function LogOutModal({
  isModalOpen,
  setModalOpen,
  isMobile,
}: {
  isModalOpen: boolean;
  isMobile: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const { handleLogOut } = useAppState();

  return (
    <BaseModal
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      showClose={false}
    >
      <>
        <img src={log_out_logo} alt="logo"></img>
        <Typography
          sx={{
            color: '#232631',
            fontWeight: 600,

            lineHeight: 'normal',
            fontSize: isMobile ? '20px' : '24px',
            marginTop: isMobile ? '16px' : '24px',
          }}
        >
          Are you sure?
        </Typography>

        <Typography
          sx={{
            color: '#232631',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: isMobile ? '16px' : '20px',
            marginTop: isMobile ? '4px' : '8px',
            marginBottom: isMobile ? '16px' : '24px',
          }}
        >
          Are you sure you want to log out of your account? You can return at
          any time convenient for you
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            '& button': {
              border: 'none',
              borderRadius: '12px',
              background: '#F5F5F5',
              padding: '19px 32px',
              color: '#232631',
              fontWeight: 600,

              lineHeight: 'normal',
              fontSize: isMobile ? '16px' : '20px',
              width: '100%',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              WebkitTapHighlightColor: 'transparent',
            },
            '& .red': {
              color: '#fff',
              background: '#FF435A',
            },
          }}
        >
          <button className="red" onClick={handleLogOut}>
            Yes, log out
          </button>
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Not now
          </button>
        </Box>
      </>
    </BaseModal>
  );
}

export default LogOutModal;
