import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  useTheme,
} from '@mui/material';
import BaseModal from './BaseModal';
import emailSvg from '../../assets/svg/images_alert_email.svg';
import { useAppState } from '../../providers/AppProvider.hooks';
import { EMAIL_REGEX } from '../../utils/constants';
import close from '../../assets/svg/mobile_close.svg';
import { restorePassword } from '../../services/firebase';

function RestorePasswordModal({
  isModalOpen,
  setModalOpen,
  isMobile,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  isMobile: boolean;
}) {
  const { isRestorePasswordModalOpen, setRestorePasswordModalOpen } =
    useAppState();
  const [isEmailSended, setEmailSended] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleRestoreClick = () => {
    if (!EMAIL_REGEX.test(email)) {
      setError(true);
    } else {
      setLoading(true);
      restorePassword(email)
        .then((resp) => {
          if (resp) {
            setEmailSended(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return isEmailSended ? (
    <BaseModal
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      showClose={false}
    >
      <>
        <img src={emailSvg} alt="logo"></img>
        <Typography
          sx={{
            color: '#232631',
            fontWeight: 600,

            lineHeight: 'normal',
            fontSize: isMobile ? '20px' : '24px',
            marginTop: isMobile ? '16px' : '24px',
          }}
        >
          Check your email
        </Typography>

        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: isMobile ? '16px' : '20px',
            marginTop: isMobile ? '4px' : '8px',
            marginBottom: isMobile ? '16px' : '24px',
          }}
        >
          We have sent instructions for changing your password to the specified
          email address.
        </Typography>

        <button
          style={{
            cursor: 'pointer',
            background: '#256BFD',
            fontSize: '17px',
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            setEmailSended(false);
            setRestorePasswordModalOpen(false);
          }}
        >
          Got it
        </button>
      </>
    </BaseModal>
  ) : (
    <Dialog open={isRestorePasswordModalOpen} fullScreen>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            padding: '24px 24px 34px',
            maxWidth: '430px',
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              position: 'absolute',
              top: '24px',
              right: '24px',
            }}
            onClick={() => {
              setRestorePasswordModalOpen(false);
            }}
            src={close}
            alt="close"
          ></img>
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '20px',
              textAlign: 'center',
            }}
          >
            Restore Password
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 400,
              lineHeight: 'normal',
              fontSize: '15px',
              textAlign: 'center',
              marginTop: '16px',
            }}
          >
            Please enter the email address you provided when creating your
            account
          </Typography>

          <Box
            sx={{
              height: '1px',
              background: 'rgb(245,245,245)',
              width: '100%',
              margin: '24px 0',
            }}
          ></Box>
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '16px',
              '& input': {
                width: '100%',
                height: '52px',
                background: 'rgb(245,245,245)',
                border: 'none',
                borderRadius: '12px',
                marginBottom: '16px',
                color: theme.palette.text.primary,
                fontFamily: 'Avenir Next',
                fontWeight: 500,
                fontSize: '17px',
                paddingLeft: '23px',
                '&:focus-visible': {
                  outline: 'none',
                },
              },
              '& input::placeholder': {
                color: theme.palette.text.secondary,
                fontFamily: 'Avenir Next',
                fontWeight: 500,
                opacity: 0.5,
                fontSize: '17px',
              },
              '& p': {
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                fontSize: '13px',
                marginBottom: '4px',
              },
              '& .textWrapper': {
                display: 'flex',
                justifyContent: 'space-between',
              },
              '& .error': {
                color: 'rgb(253, 37, 69)',
              },
            }}
          >
            <Box className="textWrapper">
              <Typography>Email address</Typography>
              {error && (
                <Typography className="error">Wrong email format</Typography>
              )}
            </Box>
            <input
              placeholder="Enter your email address"
              type="email"
              style={{
                outline: error ? '1px solid rgb(253, 37, 69)' : undefined,
              }}
              value={email}
              onChange={(e) => {
                setError(false);
                setEmail(e.target.value);
              }}
            ></input>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <button
              style={{
                cursor: 'pointer',
                background: '#256BFD',
                fontSize: '17px',
                color: '#fff',
                fontWeight: 600,
                fontFamily: 'Avenir Next',
                textTransform: 'none',
                height: '56px',
                width: '100%',
                borderRadius: '12px',
                border: 'none',
                WebkitTapHighlightColor: 'transparent',
              }}
              onClick={isLoading ? undefined : handleRestoreClick}
            >
              {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#fff',
                  }}
                ></CircularProgress>
              ) : (
                'Restore password'
              )}
            </button>
          </Box>
        </Box>
      </Box>

      <img src={emailSvg} alt="preload" width={0} height={0}></img>
    </Dialog>
  );
}

export default RestorePasswordModal;
