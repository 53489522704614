import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import BaseModal from '../../../../common/BaseModal';
import hourArrow from '../../../../../assets/svg/clock/grey-2/group_1.svg';
import clockBase from '../../../../../assets/svg/clock/grey-2/group_2.svg';
import minuteArrow from '../../../../../assets/svg/clock/grey-2/group_3.svg';

function InsightsProcessingModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <BaseModal
      showClose={false}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginLeft: '10px',
            minWidth: '104px',
            minHeight: '104px',
            borderRadius: '50%',
            background: theme.palette.grey['300'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
              position: 'absolute',
            },
          }}
        >
          <img className="hourArrow" src={hourArrow} alt="clock"></img>
          <img src={clockBase} alt="clock"></img>
          <img className="minuteArrow" src={minuteArrow} alt="clock"></img>
        </Box>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            margin: '15px 0px 10px',
            textAlign: 'center',
          }}
          variant="sTitleMS"
        >
          Insights are almost ready
        </Typography>

        <Typography
          variant="sSubTitle"
          sx={{
            color: theme.palette.text.secondary,
            margin: '0px 0px 20px',
            textAlign: 'center',
          }}
        >
          We need a little more time to prepare some useful tips and
          recommendations for you.
        </Typography>
        <button
          style={{
            cursor: 'pointer',
            background: '#256BFD',
            fontSize: '15px',
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Got it
        </button>
      </Box>
    </BaseModal>
  );
}

export default InsightsProcessingModal;
