import { initializeApp } from 'firebase/app';
import {
  indexedDBLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAsdelhCF5ZjIdGa554xdpBKBCcJBdcgAg',
  authDomain: 'followerse-370015.firebaseapp.com',
  projectId: 'followerse-370015',
  storageBucket: 'followerse-370015.appspot.com',
  messagingSenderId: '360229318856',
  appId: '1:360229318856:web:cf3ac82de99c56b987f5fd',
  measurementId: 'G-ELPKJ0RDG3',
};

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

export const signIn = async (email: string, password: string) => {
  return await setPersistence(auth, indexedDBLocalPersistence).then(() => {
    return signInWithEmailAndPassword(auth, email, password);
  });
};

export const restorePassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email)
    .then((resp) => {
      return true;
    })
    .catch((err) => {
      console.error('sendPasswordResetEmail error: ', err);
      return false;
    });
};
