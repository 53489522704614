import { Box, Typography } from '@mui/material';
import DotsSvg from '../../../../../assets/svg/dots.svg';
import { InsightsItem } from '../../../../../services/requests';

export default function InsightsItemC({
  useDelimiter,
  title,
  description,
}: InsightsItem & { useDelimiter: boolean }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 15px 20px 24px',
        '&:before': {
          position: 'absolute',
          top: '0',
          left: '0',
          content: '""',
          width: '4px',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '12px',
        },
        '&:after': useDelimiter
          ? {
              position: 'absolute',
              bottom: '-16px',
              left: '0',
              content: '""',
              width: '4px',
              height: '12px',
              backgroundImage: `url(${DotsSvg})`,
            }
          : {},
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '28px',
          textAlign: 'left',
          color: 'white',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          marginTop: '5px',
          fontFamily: 'Avenir Next',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          textAlign: 'left',
          color: 'white',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
