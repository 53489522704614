import React from 'react';
import { Box, Dialog } from '@mui/material';
import { useAppState } from '../../../../../providers/AppProvider.hooks';

function CathegoryModal() {
  const { cathegoryData, setCathegoryData } = useAppState();

  return (
    <Dialog fullScreen open={cathegoryData !== null}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '430px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              // border: '1px solid red',
              textAlign: 'center',
              position: 'relative',
              '& .close': {
                position: 'absolute',
                cursor: 'pointer',
                '&:hover': {
                  path: {
                    stroke: '#8C8E96',
                  },
                },
              },
            }}
            onClick={() => {
              setCathegoryData(null);
            }}
          >
            back
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CathegoryModal;
