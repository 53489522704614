import { fetcher } from '../utils/fetcher';
import { Nullable } from '../models/common';

type SubscriptionsResponse = {
  subscriptions: Array<{
    bundle: string;
    id: string;
    started_at: string;
    status: string;
  }>;
};
export async function getUserSubscriptions({
  token,
}: {
  token: string;
}): Promise<SubscriptionsResponse> {
  return fetcher(
    process.env.REACT_APP_RECURLY_URI + '/subscriptions/' + token,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
    }
  );
}

export enum INSIGHTS_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export type InstagramUserData = {
  private: boolean;
  username: string;
  fullName: string;
  followersCount: number;
  followsCount: number;
  postsCount: number;
  profilePicUrl: string;
  // searchTerm: string;
  // inputUrl: string;
  // id: string;
  // url: string;
  // biography: string;
  // hasChannel: boolean;
  // highlightReelCount: number;
  // isBusinessAccount: boolean;
  // joinedRecently: boolean;
  // businessCategoryName: string;
  // verified: boolean;
  // profilePicUrlHD: string;
  // igtvVideoCount: number;
  // relatedProfiles: [];
  // latestIgtvVideos: [];
  // latestPosts: [];
  // facebookPage: {
  //   id: string;
  //   category: string;
  //   imageURI: string;
  //   likes: number;
  //   verification: string;
  //   name: string;
  //   country: string | null;
  //   entityType: string;
  //   igUsername: string;
  //   igFollowers: number;
  //   igVerification: boolean;
  //   pageAlias: string;
  //   pageIsDeleted: boolean;
  // };
  _insights: {
    status: INSIGHTS_STATUS;
    estimated_completion_date: {
      _seconds: number;
      _nanoseconds: number;
    };
    prevData: {
      insights: Array<InsightsItem>;
      recommendationsForIncreasingEngagement: Array<InsightsItem>;
      recommendationsForIncreasingFollowers: Array<InsightsItem>;
      recommendationsBasedOnInsights: Array<InsightsItem>;
    };
    lastData: {
      insights: Array<InsightsItem>;
      recommendationsForIncreasingEngagement: Array<InsightsItem>;
      recommendationsForIncreasingFollowers: Array<InsightsItem>;
      recommendationsBasedOnInsights: Array<InsightsItem>;
    };
  };
  _news: Array<NewsItem>;
  _calculatedData: {
    overviewStatistics: {
      totalPostsAnalyzed: number;
      totalLikes: number;
      totalComments: number;
    };
    userEngagement: {
      engagementRate: {
        status: string;
        value: number;
        cell: number;
      };
      averageCommentsPerPost: number;
      averageLikesPerPost: number;
    };
    topPosts: {
      mostLikedPosts: Array<TopPostItem>;
      mostCommentedPosts: Array<TopPostItem>;
    };
    audience: {
      secretFans: InstagramUserDataCategory;
      lostFollowers: InstagramUserDataCategory;
      newFollowers: InstagramUserDataCategory;
      inactiveFollowers: InstagramUserDataCategory;
      friends: InstagramUserDataCategory;
      fans: InstagramUserDataCategory;
      blockedYou: InstagramUserDataCategory;
    };
    other: {
      likes: InstagramUserDataCategory;
      comments: InstagramUserDataCategory;
    };
  };
  _updatedAt: {
    _seconds: number;
    _nanoseconds: number;
  };
};

export type InsightsItem = {
  title: string;
  description: string;
};

export type NewsItem = {
  title: string;
  text: Array<string>;
  imagePath: string;
  slug: string;
};

export type TopPostItem = {
  postPicUrl: string;
  date: string;
  user: string;
  count: number;
};

export type InstagramUserDataCategory = {
  totalValue: number | null;
  items: Array<CategoryObj | CategoryErrObj>;
};

export type CategoryObj = {
  postUrl: string;
  likesCount: number;
  ownerUsername: string;
  ownerProfilePicUrl: string;
  id: string;
  text: string;
  timestamp: string;
};

export type CategoryErrObj = {
  extensions: {
    is_final: boolean;
  };
  data: null;
  errors: [];
};

export type InstagramUserSuccessResponse = {
  statusCode: number;
  response: InstagramUserData;
};

export type InstagramUserErrorResponse = {
  statusCode: number;
  message: string;
};

export async function fetchInstagramUser({
  username,
  subscriptionId,
  uid,
  environment,
}: {
  username: string;
  subscriptionId: Nullable<string>;
  uid: Nullable<string>;
  environment: Nullable<string>;
}): Promise<InstagramUserSuccessResponse | InstagramUserErrorResponse> {
  return fetcher(
    'https://us-central1-followerse-370015.cloudfunctions.net/ig-getPublicProfile',
    {
      method: 'POST',
      body: JSON.stringify({
        usernames: [username],
        subscriptionId: subscriptionId,
        uid: uid,
        environment: environment,
        // forceUpdate: true,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export async function sendPDFReportData({
  username,
  email,
  uid,
}: {
  username: string;
  email: string | null;
  uid: string;
}): Promise<any> {
  return fetcher(
    'https://us-central1-followerse-370015.cloudfunctions.net/ig-getPdfReport',
    {
      method: 'POST',
      body: JSON.stringify({
        username,
        email,
        uid,
        hostname: window.location.hostname,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}
