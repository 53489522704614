import { InsightsItem } from '../../../../../services/requests';
import { Box, Typography, useTheme } from '@mui/material';
import CheckMarkSvg from '../../../../../assets/svg/check-mark.svg';

export default function RecommendationItem({
  title,
  description,
}: InsightsItem) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '17px 15px 20px 69px',
        background: '#56AC141A',
        borderRadius: '16px',
        '&:before': {
          position: 'absolute',
          top: '15px',
          left: '15px',
          content: '""',
          width: '32px',
          height: '32px',
          backgroundImage: `url(${CheckMarkSvg})`,
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '28px',
          textAlign: 'left',
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          marginTop: '5px',
          fontFamily: 'Avenir Next',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          textAlign: 'left',
          color: theme.palette.text.primary,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
