import { useEffect } from 'react';

export default function usePreloadImages({
  images,
}: {
  images: Array<string>;
}) {
  useEffect(() => {
    Promise.all([
      ...images.map((imageSrc) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = imageSrc;
          loadImg.onload = () => resolve(imageSrc);
          loadImg.onerror = (err) => reject(err);
        });
      }),
    ])
      .then((allPromises: any) => {
        // console.log('All images loaded');
      })
      .catch((err) => {});
  }, [images]);
}
