import { Box } from '@mui/material';
import React from 'react';

function Loader() {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        zIndex: 100,
      }}
    >
      <span className="appLoader"></span>
    </Box>
  );
}

export default Loader;
