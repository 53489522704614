import React from 'react';
import { Box, Dialog, Typography, useTheme } from '@mui/material';

import clock from '../../../../../assets/svg/clock.svg';

function AnalysingAccount() {
  const theme = useTheme();

  return (
    <Dialog open fullScreen>
      <Box
        sx={{
          width: '100%',
          maxWidth: '430px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          minHeight: '100%',
          paddingTop: '90px',
        }}
      >
        <Box
          sx={{
            minWidth: '208px',
            minHeight: '208px',
            background: 'rgba(37, 107, 253, 0.1)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span className="analysingLoader"></span>
        </Box>

        <Typography
          sx={{ color: theme.palette.text.primary, margin: '32px 24px 8px' }}
          variant="sTitleS"
        >
          Analysing your account
        </Typography>

        <Typography
          sx={{
            color: theme.palette.text.secondary,
            margin: '0px 24px 32px',
            textAlign: 'center',
          }}
          variant="sSubTitle"
        >
          Your profile data is being processed, detailed profile analysis will
          be available in a few seconds
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'rgb(245,245,245)',
            paddingTop: '32px',
          }}
        >
          <img width={40} height={40} src={clock} alt="clock"></img>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              margin: '16px 24px 34px',
              textAlign: 'center',
              '& .bold': {
                fontWeight: 600,
                color: theme.palette.text.primary,
              },
            }}
            variant="sSubTitle"
          >
            From now on, your data is being recorded and tracked in real time.
            It takes time for our algorithms to analyse all the data, so{' '}
            <span className="bold">
              the first analysis might not show the whole picture.
            </span>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AnalysingAccount;
