import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '430px',
          margin: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
