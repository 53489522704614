import { Box, Typography, useTheme } from '@mui/material';
import { useAppState } from '../../../../../providers/AppProvider.hooks';

export default function UserEngagement() {
  const { instagramUserData } = useAppState();

  const theme = useTheme();

  const titleStyles = {
    fontFamily: 'Avenir Next',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19.12px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  };

  const subTitleStyles = {
    fontFamily: 'Avenir Next',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    textAlign: 'left',
    primary: theme.palette.text.primary,
  };

  const itemStyles = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '2px',
    padding: '20px 17px',
    background: theme.palette.grey['300'],
    borderRadius: '16px',
  };

  const colors: Record<string, string> = {
    1: '#F63C47',
    2: '#FF9431',
    3: '#E9C81C',
    4: '#50CC1A',
    5: '#11BE42',
  };

  return instagramUserData?._calculatedData?.userEngagement ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          background: theme.palette.grey['300'],
          borderRadius: '16px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19.12px',
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }}
        >
          Overall Engagement Rate
        </Typography>
        <Typography
          sx={{
            margin: '5px 0 0',
            fontFamily: 'Avenir Next',
            fontSize: '40px',
            fontWeight: '700',
            lineHeight: '55px',
            textAlign: 'center',
            color: theme.palette.text.primary,
          }}
        >
          {
            instagramUserData._calculatedData.userEngagement.engagementRate
              .status
          }
        </Typography>
        <Box sx={{ display: 'flex', gap: '5px', margin: '10px 0 0 0' }}>
          {Array.from({ length: 5 }).map((i, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                borderRadius: '8px',
                minHeight: '30px',
                background:
                  index + 1 <=
                  instagramUserData._calculatedData.userEngagement
                    .engagementRate.cell
                    ? colors[
                        instagramUserData._calculatedData.userEngagement
                          .engagementRate.cell
                      ] ?? colors[1]
                    : theme.palette.grey['400'],
              }}
            />
          ))}
        </Box>
        <Typography
          sx={{
            margin: '15px 0 0 0',
            fontFamily: 'Avenir Next',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19.12px',
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }}
        >
          *calculated as total likes + comments divided by total posts
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '15px' }}>
        <Box sx={itemStyles}>
          <Typography sx={titleStyles}>Avg. Likes/Post</Typography>
          <Typography sx={subTitleStyles}>
            {
              instagramUserData._calculatedData.userEngagement
                .averageLikesPerPost
            }
          </Typography>
        </Box>
        <Box sx={itemStyles}>
          <Typography sx={titleStyles}>Avg. Comments/Post</Typography>
          <Typography sx={subTitleStyles}>
            {
              instagramUserData._calculatedData.userEngagement
                .averageCommentsPerPost
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : null;
}
