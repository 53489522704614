import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppState } from '../../providers/AppProvider.hooks';
import { AppRoutes } from '../../utils/constants';
import Loader from '../Loader';
import { resolveUserCredentials } from '../../utils/credentials';

const ProvideRoute = () => {
  const { isUserAuthorized } = useAppState();

  const loginCredentials = useMemo(
    () =>
      resolveUserCredentials(
        new URLSearchParams(window.location.search).get('creds')
      ),
    []
  );

  if (loginCredentials !== null && !isUserAuthorized) {
    return <Loader />;
  }

  if (isUserAuthorized) {
    return <Navigate to={AppRoutes.DASHBOARD} />;
  }

  if (!isUserAuthorized) {
    return <Navigate to={AppRoutes.SIGN_IN} />;
  }

  return <Loader />;
};

export default ProvideRoute;
