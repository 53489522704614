import { Box, SxProps, Theme } from '@mui/material';

export default function Divider(props: SxProps<Theme>) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '1px',
        background: 'rgba(35,38,49, 0.1)',
        ...props,
      }}
    />
  );
}
