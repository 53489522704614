import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import not_found from '../../../../../assets/images/dashboard/account/images_user_not_found.svg';
import BaseModal from '../../../../common/BaseModal';

function AccountNotFoundModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <BaseModal showClose={false} isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img width={120} height={120} src={not_found} alt="not_found"></img>
        <Typography
          sx={{ color: theme.palette.text.primary, margin: '24px 0px 8px', textAlign: 'center' }}
          variant="sTitleMS"
        >
          Account not found
        </Typography>

        <Typography
          variant="sSubTitle"
          sx={{ color: theme.palette.text.secondary, margin: '0px 0px 24px', textAlign: 'center' }}
        >
          Unfortunately, we were unable to find such an account to analyze it. Check that the name you entered is
          correct
        </Typography>
        <button
          style={{
            cursor: 'pointer',
            background: '#256BFD',
            fontSize: '15px',
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Got it
        </button>
      </Box>
    </BaseModal>
  );
}

export default AccountNotFoundModal;
