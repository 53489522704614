import PageLayout from '../../components/PageLayout';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { resolveRelativeImageUrl } from '../../../../utils/posts';
import { AppRoutes } from '../../../../utils/constants';
import ArrowIcon from '../../../../assets/svg/arrow-left.svg';
import useScrollTop from '../../../../hooks/useScrollTop';
export default function NewsPost() {
  useScrollTop();

  const { slug } = useParams();
  const { instagramUserData } = useAppState();
  const theme = useTheme();
  const navigate = useNavigate();

  const post = instagramUserData?._news?.find((n) => n.slug === slug) ?? null;

  const toNews = () => navigate(AppRoutes.NEWS);

  return (
    <PageLayout title="News">
      {post ? (
        <Box
          sx={{
            '& > .main-img': {
              maxWidth: '100%',
              position: 'relative',
              // margin: '-30px -20px 0',
            },
          }}
        >
          <img
            className="main-img"
            src={resolveRelativeImageUrl(post.imagePath)}
            alt={post.title}
          />
          <Box
            sx={{
              padding: '30px 20px 0',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontSize: '24px',
                fontWeight: '600',
                lineHeight: '32.78px',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
            >
              {post.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                margin: '20px 0 0 0',
              }}
            >
              {post.text.map((value, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontFamily: 'Avenir Next',
                    fontSize: '14px',
                    fontWeight: '40',
                    lineHeight: '21.86px',
                    color: theme.palette.text.primary,
                  }}
                >
                  {value}
                </Typography>
              ))}
            </Box>
            <Button
              sx={{
                position: 'relative',
                margin: '40px 0 0',
                width: '100%',
                padding: '19px',
                background: theme.palette.grey['300'],
                fontFamily: 'Avenir Next',
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: '27.32px',
                textAlign: 'center',
                borderRadius: '12px',
                textTransform: 'initial',
                color: theme.palette.text.primary,
                '&:hover': {
                  background: theme.palette.grey['300'],
                },
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                  display: 'block',
                  width: '24px',
                  height: '24px',
                  backgroundImage: `url(${ArrowIcon})`,
                },
              }}
              onClick={toNews}
            >
              To all news
            </Button>
          </Box>
        </Box>
      ) : null}
    </PageLayout>
  );
}
