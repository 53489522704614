import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import footer_image from '../../../assets/images/dashboard/footer_image.webp';
import { AppRoutes } from '../../../utils/constants';
import { navigationList } from '../../../constants/navigation';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 0 22px',
          '& > .footer-bg-img': {
            position: 'absolute',
            objectFit: 'contain',
            width: '100%',
            height: '100%',
            top: '-16px',
            pointerEvents: 'none',
          },
        }}
      >
        <img className="footer-bg-img" src={footer_image} alt="bg-image" />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '108px',
          }}
        >
          <img width={64} height={64} src={'/logo128.webp'} alt="logo"></img>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '20px',
                color: '#232631',
                lineHeight: '28px',
                textAlign: 'center',
              }}
            >
              MetricMaster
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                color: '#8C8E96',
                textAlign: 'center',
                lineHeight: '20px',
              }}
            >
              Profile Analyzer
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            margin: '20px 0 0 0',
            gap: '24px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {navigationList.map((navItem, index) => (
            <Typography
              key={index}
              onClick={() => {
                navigate(navItem.path);
              }}
              sx={{
                fontWeight: 600,
                cursor: 'pointer',
                fontSize: '16px',
                color: theme.palette.text.primary,
                textAlign: 'center',
                lineHeight: '22px',
              }}
            >
              {navItem.title}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            height: '1px',
            width: 'calc(100% - 40px)',
            margin: '20px 0',
            background: 'rgba(35,38,49, 0.1)',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <Typography
            onClick={() => {
              window.open(AppRoutes.TERMS, '_blank');
            }}
            sx={{
              fontWeight: 400,
              cursor: 'pointer',
              fontSize: '14px',
              color: '#8C8E96',
              textAlign: 'center',
              lineHeight: 'normal',
            }}
          >
            Terms of Service
          </Typography>

          <Typography
            onClick={() => {
              window.open(AppRoutes.PRIVACY, '_blank');
            }}
            sx={{
              fontWeight: 400,
              cursor: 'pointer',
              fontSize: '14px',
              color: '#8C8E96',
              textAlign: 'center',
              lineHeight: 'normal',
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
