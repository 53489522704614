import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import is_private from '../../../../../assets/images/dashboard/account/images_private_account.svg';
import BaseModal from '../../../../common/BaseModal';
import { APP_CONSTANTS } from '../../../../../utils/constants';

function AccountIsPrivateModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <BaseModal showClose={false} isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img width={120} height={120} src={is_private} alt="is_private"></img>
        <Typography
          sx={{ color: theme.palette.text.primary, margin: '24px 0px 8px', textAlign: 'center' }}
          variant="sTitleMS"
        >
          Your account is private
        </Typography>

        <Typography
          variant="sSubTitle"
          sx={{ color: theme.palette.text.secondary, margin: '0px 0px 24px', textAlign: 'center' }}
        >
          Unfortunately, the account you specified is private. Make it public to get analysis of your audience and
          engagement
        </Typography>
        <button
          style={{
            cursor: 'pointer',
            background: '#256BFD',
            fontSize: '15px',
            color: '#fff',
            fontWeight: 600,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '56px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            window.open(APP_CONSTANTS.MAKE_ACCOUNT_PUBLIC_URL, '_blank');
          }}
        >
          How to make account public?
        </button>
        <button
          style={{
            marginTop: '24px',
            cursor: 'pointer',
            background: 'transparent',
            fontSize: '15px',
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontFamily: 'Avenir Next',
            textTransform: 'none',
            height: '23px',
            width: '100%',
            borderRadius: '12px',
            border: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </button>
      </Box>
    </BaseModal>
  );
}

export default AccountIsPrivateModal;
