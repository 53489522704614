import { Box, Typography } from '@mui/material';
import HeadImg from '../../../../../assets/images/dashboard/analytics-head-bg.webp';
import React from 'react';
import { useAppState } from '../../../../../providers/AppProvider.hooks';

export default function IntroductionToAnalytics() {
  const { instagramUserData } = useAppState();

  return instagramUserData?._calculatedData?.overviewStatistics ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '33px',
          textAlign: 'center',
        }}
      >
        Analysis of your Instagram account based on
      </Typography>

      <Box
        sx={{
          padding: '15px 0',
          margin: '0 -20px',
          position: 'relative',
          '& > .analytics-head-img': {
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          },
        }}
      >
        <img
          className="analytics-head-img"
          src={HeadImg}
          alt="analytics-head-img"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '154px',
            height: '104px',
            borderRadius: '16px',
            color: 'white',
            margin: '0 auto',
            background: 'linear-gradient(227.66deg, #C92EFF 0%, #2B41DB 100%)',
            boxShadow: '0px 10px 20px 0px #553DE533',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '40px',
              fontWeight: '700',
              lineHeight: '55px',
              textAlign: 'center',
              color: 'white',
            }}
          >
            {instagramUserData._calculatedData.overviewStatistics
              .totalPostsAnalyzed ?? 0}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '19.12px',
              textAlign: 'center',
              color: 'white',
            }}
          >
            latest posts
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : null;
}
