import { useAppState } from '../../../providers/AppProvider.hooks';
import { useEffect, useMemo, useState } from 'react';

import {
  fetchInstagramUser,
  INSIGHTS_STATUS,
  InstagramUserSuccessResponse,
} from '../../../services/requests';

import { Nullable } from '../../../models/common';

import { AppRoutes, STORAGE_USERNAME_KEY } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

export default function useEstimatedInsightsResponse(): [
  Nullable<string>,
  () => void,
] {
  const navigate = useNavigate();

  const {
    firebaseUserCredential,
    subscriptionId,
    instagramUserData,
    setInstagramUserData,
    isCompletedInsights,
    setIsShowProcessingInsightsModal,
  } = useAppState();

  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  const [canIReloadProfile, setCanIReloadProfile] = useState(false);

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const insightsEstimatedCompletionTime = useMemo(() => {
    return instagramUserData?._insights?.status
      ? [INSIGHTS_STATUS.PENDING, INSIGHTS_STATUS.PROCESSING].includes(
          instagramUserData?._insights?.status
        )
        ? instagramUserData?._insights?.estimated_completion_date?._seconds ??
          null
        : null
      : null;
  }, [instagramUserData]);

  useEffect(() => {
    if (insightsEstimatedCompletionTime && !isCompletedInsights) {
      let interval: Nullable<NodeJS.Timer> = null;

      const handle = () => {
        function getTimeDifference(dateX: number, dateY: number) {
          const startDate = new Date(dateX);
          const endDate = new Date(dateY);

          const difference = endDate.getTime() - startDate.getTime();

          const totalSeconds = Math.floor(difference / 1000);

          const minutes = Math.floor(totalSeconds / 60);

          const seconds = totalSeconds % 60;

          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');

          return {
            ui: `${formattedMinutes}:${formattedSeconds}`,
            minutes: minutes,
            seconds: seconds,
          };
        }

        const { ui, minutes, seconds } = getTimeDifference(
          new Date().getTime(),
          insightsEstimatedCompletionTime * 1000
        );

        if (minutes <= 0 && seconds <= 0) {
          setCanIReloadProfile(true);

          if (interval) {
            clearInterval(interval);
          }
        } else {
          setTimeLeft(ui);
        }
      };

      interval = setInterval(handle, 1000);

      handle();

      return () => (interval ? clearInterval(interval) : undefined);
    }
  }, [insightsEstimatedCompletionTime, isCompletedInsights]);

  useEffect(() => {
    if (canIReloadProfile && !isLoadingProfile) {
      setIsLoadingProfile(true);

      const username = window.localStorage.getItem(STORAGE_USERNAME_KEY);

      if (username && username !== '') {
        fetchInstagramUser({
          username: username,
          subscriptionId,
          uid: firebaseUserCredential?.uid ?? null,
          environment: process.env.REACT_APP_ENVIRONMENT ?? null,
        })
          .then((response) => {
            const data = response as InstagramUserSuccessResponse;
            setInstagramUserData(data.response);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoadingProfile(false);
            setCanIReloadProfile(false);
            setTimeLeft(null);
          });
      }
    }
  }, [canIReloadProfile, isLoadingProfile]);

  function onClickByCard() {
    if (timeLeft) {
      setIsShowProcessingInsightsModal(true);
    } else {
      navigate(AppRoutes.INSIGHTS);
    }
  }

  return [timeLeft, onClickByCard];
}
