import { InsightsItem } from '../../../../../services/requests';
import { Box, Typography, useTheme } from '@mui/material';

export default function StepRecommendationItem({
  title,
  description,
  step,
}: InsightsItem & { step: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        background: '#56AC141A',
        borderRadius: '16px',
      }}
    >
      <Typography
        sx={{
          maxWidth: 'max-content',
          fontFamily: 'Avenir Next',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '16.39px',
          textAlign: 'left',
          color: 'white',
          textTransform: 'uppercase',
          padding: '10px 15px',
          borderRadius: '20px',
          background: '#56AC14',
        }}
      >
        {step}
      </Typography>
      <Typography
        sx={{
          marginTop: '10px',
          fontFamily: 'Avenir Next',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '28px',
          textAlign: 'left',
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          marginTop: '5px',
          fontFamily: 'Avenir Next',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          textAlign: 'left',
          color: theme.palette.text.primary,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}
