export function mergeValueAndPostfixes(
  value: number | string,
  postfixes: [string, string]
) {
  return value > 1
    ? `${value} ${postfixes[1]}`
    : value === 1
      ? `${value} ${postfixes[0]}`
      : `${value} ${postfixes[1]}`;
}

export function resolveImageUrl(imageUrl: string) {
  return 'https://cors.huracanapps.com/' + imageUrl;
}

export function resolveRelativeImageUrl(imagePath: string) {
  return 'https://assets.followersreport.com/' + imagePath;
}
