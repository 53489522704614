import React from 'react';
import { Box } from '@mui/material';
import NavigationCard from './components/NavigationCard';
import { AppRoutes } from '../../../../utils/constants';
import AnalyticsImage from '../../../../assets/images/dashboard/navigation-card/analytics-bg-card.webp';
import InsightsImage from '../../../../assets/images/dashboard/navigation-card/insights-bg-card.webp';
import InsightsImageOp from '../../../../assets/images/dashboard/navigation-card/insights--op-bg-card.webp';
import NewsImage from '../../../../assets/images/dashboard/navigation-card/news-bg-card.webp';

import useEstimatedInsightsResponse from '../../hooks/useEstimatedInsightsResponse';
import { useNavigate } from 'react-router-dom';

function Main() {
  const [timeLeft, onClickByInsightsCard] = useEstimatedInsightsResponse();

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px',
        gap: '15px',
      }}
    >
      <NavigationCard
        title="Analytics"
        description="Analysis of your account metrics and statistics"
        onClick={() => navigate(AppRoutes.ANALYTICS)}
        image={AnalyticsImage}
      />
      <NavigationCard
        title="Insights"
        description="Find out how to improve your account metrics"
        onClick={onClickByInsightsCard}
        timeLeft={timeLeft}
        image={timeLeft ? InsightsImageOp : InsightsImage}
      />
      <NavigationCard
        title="News"
        description="Stay up to date with current and popular trends"
        onClick={() => navigate(AppRoutes.NEWS)}
        image={NewsImage}
      />
    </Box>
  );
}

export default Main;
