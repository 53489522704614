import React from 'react';
import ArrowIcon from '../../../assets/svg/arrow-right-2.svg';
import { Button, ButtonProps } from '@mui/material';

export default function BaseButton({
  withIcon,
  ...rest
}: ButtonProps & { withIcon?: boolean }) {
  return (
    <Button
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        padding: '19px',
        fontFamily: 'Avenir Next',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27.32px',
        textAlign: 'center',
        borderRadius: '12px',
        background: '#256BFD',
        textTransform: 'initial',
        color: 'white',
        '& > .icon': {
          position: 'absolute',
          right: '15px',
          top: '50%',
          transform: 'translateY(-50%)',
        },
        '&:hover': {
          background: '#256BFD',
        },
      }}
      {...rest}
    >
      <span>{rest.children}</span>
      {withIcon && <img className="icon" src={ArrowIcon} alt="icon" />}
    </Button>
  );
}
