import PageLayout from '../../components/PageLayout';
import { Box, Typography, useTheme } from '@mui/material';
import Bulb2 from '../../../../assets/images/dashboard/bulb-2.webp';
import Bell from '../../../../assets/images/dashboard/bell.webp';
import Divider from '../../components/Divider';
import NavigationBlock from '../../components/NavigationBlock';
import { AppRoutes } from '../../../../utils/constants';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { INSIGHTS_STATUS } from '../../../../services/requests';
import { transformInsightsItem } from './Insights.helpers';

import {
  InsightsItem,
  RecommendationItem,
  StepRecommendationItem,
} from './components';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import useScrollTop from '../../../../hooks/useScrollTop';

export default function Insights() {
  useScrollTop();

  const theme = useTheme();
  const navigate = useNavigate();
  const { instagramUserData, setIsShowProcessingInsightsModal } = useAppState();

  const sectionTitleStyles = {
    marginTop: '40px',
    fontFamily: 'Avenir Next',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32.78px',
    textAlign: 'left',
    marginBottom: '20px',
    color: theme.palette.text.primary,
  };

  useLayoutEffect(() => {
    if (instagramUserData?._insights?.status) {
      const isNotCompleted = [
        INSIGHTS_STATUS.PENDING,
        INSIGHTS_STATUS.PROCESSING,
      ].includes(instagramUserData._insights.status);

      if (isNotCompleted) {
        setIsShowProcessingInsightsModal(true);
        navigate(AppRoutes.DASHBOARD);
      }
    }
  }, [instagramUserData]);

  return instagramUserData?._insights ? (
    <PageLayout title="Tips & Insights">
      <Box
        sx={{
          padding: '30px 20px 0',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            '& > img': {
              position: 'absolute',
              top: '-65%',
              right: '-20px',
              width: '120px',
              height: '290px',
              pointerEvents: 'none',
            },
          }}
        >
          <Typography
            sx={{
              maxWidth: '233px',
              fontFamily: 'Avenir Next',
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '32.78px',
              textAlign: 'left',
              color: theme.palette.text.primary,
              '& > span': {
                color: '#256BFD',
              },
            }}
          >
            We have prepared some tips to help{' '}
            <span>improve the performance</span> of your Instagram account.
          </Typography>
          <img src={Bulb2} alt="bulb-2" />
        </Box>
        <Divider margin="40px 0 0" />
        <Typography sx={sectionTitleStyles}>
          Recommendations for Increasing Engagement
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {instagramUserData?._insights?.lastData?.recommendationsForIncreasingEngagement.map(
            (item, index) => <RecommendationItem key={item.title} {...item} />
          )}
        </Box>
        <Typography sx={sectionTitleStyles}>
          Recommendations for Increasing Followers
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 0 60px',
          }}
        >
          {instagramUserData?._insights?.lastData?.recommendationsForIncreasingFollowers.map(
            (item, index) => <RecommendationItem key={item.title} {...item} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '40px 20px 60px',
            margin: '0 -20px',
            gap: '20px',
            background:
              'linear-gradient(182.66deg, #C92EFF 2.22%, #2B41DB 97.78%)',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '40px',
              fontWeight: '600',
              lineHeight: '54.64px',
              color: 'white',
            }}
          >
            Insights
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {instagramUserData?._insights?.lastData?.insights.map(
              (item, index) => (
                <InsightsItem
                  key={item.title}
                  {...item}
                  useDelimiter={
                    index !==
                    instagramUserData?._insights?.lastData?.insights?.length - 1
                  }
                />
              )
            )}
          </Box>
        </Box>

        <Typography sx={{ ...sectionTitleStyles, marginTop: '60px' }}>
          To summarize all of the above, we can formulate the following basic
          recommendations:{' '}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {instagramUserData?._insights?.lastData?.recommendationsBasedOnInsights.map(
            (item, index) => (
              <StepRecommendationItem
                key={item.title}
                {...transformInsightsItem(item, index)}
              />
            )
          )}
        </Box>
        <Divider margin="30px 0 40px" />
        <NavigationBlock
          image={Bell}
          title="Keep up to date with the latest news in the field of promotion of Instagram accounts and learn new things"
          buttonText="Read news"
          path={AppRoutes.NEWS}
        />
      </Box>
    </PageLayout>
  ) : null;
}
