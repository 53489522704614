import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import clockBase from '../../../../../assets/svg/clock/group_2.svg';
import hourArrow from '../../../../../assets/svg/clock/group_1.svg';
import minuteArrow from '../../../../../assets/svg/clock/group_3.svg';
import { useAppState } from '../../../../../providers/AppProvider.hooks';
import { Duration, intervalToDuration } from 'date-fns';

function NextUpdateModal() {
  const { instagramUserData } = useAppState();
  const [timeLeft, setTimeLeft] = useState<Duration | null>(null);

  useEffect(() => {
    if (instagramUserData && instagramUserData?._updatedAt?._seconds) {
      const handle = () => {
        const oneDayInSec = 24 * 60 * 60;
        const interval = intervalToDuration({
          start: Date.now(),
          end: (instagramUserData._updatedAt._seconds + oneDayInSec) * 1000,
        });

        setTimeLeft(interval);
      };

      handle();

      const interval = setInterval(handle, 1000);

      return () => clearInterval(interval);
    }
  }, [instagramUserData]);

  const timeLeftString = useMemo(() => {
    let result = '';
    if (timeLeft) {
      if (timeLeft.months && timeLeft.months > 0) {
        result = result.concat(` ${timeLeft.months}m`);
      }
      if (timeLeft.days && timeLeft.days > 0) {
        result = result.concat(` ${timeLeft.days}d`);
      }
      if (timeLeft.hours && timeLeft.hours > 0) {
        result = result.concat(` ${timeLeft.hours}h`);
      }
      if (timeLeft.minutes && timeLeft.minutes > 0) {
        result = result.concat(` ${timeLeft.minutes}m`);
      }
      if (timeLeft.seconds && timeLeft.seconds > 0) {
        result = result.concat(` ${timeLeft.seconds}s`);
      }
    }
    return result || '---';
  }, [timeLeft]);

  return timeLeftString === '---' ? null : (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        width: '100%',
        backgroundColor: 'rgb(37, 107, 253)',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px -0px 10px 0px rgb(37, 107, 253)',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '430px',
          position: 'relative',
          padding: '24px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '15px',
              color: '#fff',
              fontWeight: 400,
              fontFamily: 'Avenir Next',
            }}
          >
            It takes time for our algorithms to analyse your profile data.
          </Typography>
          <Typography
            sx={{
              marginTop: '8px',
              fontSize: '15px',
              color: '#fff',
              fontWeight: 400,
              fontFamily: 'Avenir Next',
            }}
          >
            Next update in{' '}
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {timeLeftString}
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            minWidth: '72px',
            minHeight: '72px',
            borderRadius: '50%',
            background: 'rgb(52, 131, 255)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
              position: 'absolute',
            },
          }}
        >
          <img className="hourArrow" src={hourArrow} alt="clock"></img>
          <img src={clockBase} alt="clock"></img>
          <img className="minuteArrow" src={minuteArrow} alt="clock"></img>
        </Box>
      </Box>
    </Box>
  );
}

export default NextUpdateModal;
