import React, { ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowIcon from '../../../assets/svg/arrow-narrow-left.svg';
import Footer from './Footer';
import Layout from './Layout';
import background from '../../../assets/svg/dashboard_mobile_background.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../utils/constants';
import NextUpdateModal from './Main/components/NextUpdateModal';

export default function PageLayout({
  children,
  title,
  useBackgroundGradient = true,
  showUpdateModal,
}: {
  children: ReactNode;
  title: string;
  useBackgroundGradient?: boolean;
  showUpdateModal?: boolean;
}) {
  const navigate = useNavigate();

  function onBack() {
    navigate(AppRoutes.DASHBOARD);
  }

  return (
    <Layout>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            minHeight: '78px',
            borderBottom: `1px solid rgba(35,38,49, 0.1)`,
          }}
        >
          <Button
            onClick={onBack}
            sx={{
              width: '44px',
              height: '44px',
              position: 'absolute',
              top: '17px',
              left: '10px',
            }}
          >
            <img src={ArrowIcon} alt="back-button" />
          </Button>

          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '28px',
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>{children}</Box>
        <Box>
          <Footer />
        </Box>
      </Box>

      {showUpdateModal && <NextUpdateModal />}
    </Layout>
  );
}
