import { ConstantMap, FetchStatus } from '../models/common';

export const APP_CONSTANTS = {
  SUPPORT: 'support@followersreport.com',
  MAKE_ACCOUNT_PUBLIC_URL:
    'https://help.instagram.com/284802804971822/?helpref=hc_fnav',
} as const;

export enum AppRoutes {
  SIGN_IN = '/sign-in',
  DASHBOARD = '/dashboard',
  INSIGHTS = '/dashboard/insights',
  NEWS = '/dashboard/news',
  NEWS_POST = '/dashboard/news/:slug',
  ANALYTICS = '/dashboard/analytics',
  HOME = '*',
  PRIVACY = '/privacy',
  TERMS = '/terms-of-use',
}

export const FETCH_STATUSES: ConstantMap<FetchStatus> = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const mediaQueries = {
  s: '(max-width: 600px)',
  m: '(max-width: 800px)',
  l: '(max-width: 1024px)',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const STORAGE_USERNAME_KEY = 'igusername';
export const STORAGE_PDF_REPORT_SENDED_KEY = 'pdfreportdatasended';
