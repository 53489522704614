import { Box, Typography, useTheme } from '@mui/material';
import { useAppState } from '../../../../../providers/AppProvider.hooks';
import HeartIcon from '../../../../../assets/svg/heart-icon.svg';

import {
  InstagramUserData,
  TopPostItem,
} from '../../../../../services/requests';
import { transformISOtoPrettyFormat } from '../../../../../utils/date';
import {
  mergeValueAndPostfixes,
  resolveImageUrl,
} from '../../../../../utils/posts';

const Post = ({
  prefixIcon,
  postPicUrl,
  user,
  date,
  count,
}: TopPostItem & {
  prefixIcon?: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        borderRadius: '16px',
        gap: '15px',
        background: theme.palette.grey['300'],
        '& > .post-img': {
          minWidth: '80px',
          width: '80px',
          height: '80px',
          borderRadius: '10px',
          overflow: 'hidden',
          objectFit: 'cover',
          background: theme.palette.grey['300'],
        },
      }}
    >
      <img className="post-img" src={postPicUrl} alt={user} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19.12px',
            color: theme.palette.text.secondary,
          }}
        >
          {date}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '28px',
            color: theme.palette.text.primary,
          }}
        >
          {prefixIcon && <img src={prefixIcon} alt="icon" />}
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

export default function Posts(props: {
  postsKey: keyof InstagramUserData['_calculatedData']['topPosts'];
}) {
  const { instagramUserData } = useAppState();

  const icons: Record<typeof props.postsKey, any> = {
    mostLikedPosts: HeartIcon,
    mostCommentedPosts: HeartIcon,
  };

  const postfixes: Record<typeof props.postsKey, any> = {
    mostLikedPosts: ['like', 'likes'],
    mostCommentedPosts: ['comment', 'comments'],
  };

  const posts = instagramUserData?._calculatedData?.topPosts
    ? instagramUserData?._calculatedData?.topPosts[props.postsKey]
    : [];

  return instagramUserData?._calculatedData?.topPosts ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {posts.map((post, index) => (
        <Post
          key={index}
          {...post}
          postPicUrl={resolveImageUrl(post.postPicUrl)}
          count={
            mergeValueAndPostfixes(
              post.count,
              postfixes[props.postsKey]
            ) as any as number
          }
          date={transformISOtoPrettyFormat(post.date)}
          prefixIcon={icons[props.postsKey]}
        />
      ))}
    </Box>
  ) : null;
}
